<template>
  <div class="wameed-dashboard-page-content mb-5">
    <section class="wameed-dashboard-page-content_body">
      <b-row v-if="getWorkDetails" class="no-gutters py-1 px-2">
        <b-col lg="4" md="4" class="px-0">
          <div class="py-3">
            <b-card no-body class="cursor-pointer wameed-card">
              <b-card-header class="flex-nowrap align-items-start pb-1">
                <b-media no-body class="flex-wrap">
                  <b-media-aside class="mb-4">
                    <b-avatar variant="body-bg" rounded size="88">
                      <component
                        v-bind:is="
                          entities[getWorkDetails.entity_type - 1].icon
                        "
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0 text-bold-20 text-font-main mb-3 mx-2">
                      {{ getWorkDetails.name }}
                    </h5>
                    <h5 class="mb-0 text-book-18 text-font-sub mb-3 mx-2">
                      {{ $t(entities[getWorkDetails.entity_type - 1].text) }}
                    </h5>
                    <h5 class="text-book-16 text-font-secondary mb-3 mx-2">
                      {{ formatedDate(getWorkDetails.created_at) }}
                    </h5>
                  </b-media-body>
                </b-media>
              </b-card-header>
            </b-card>
          </div>
          <div class="my-2 py-3">
            <b-card no-body class="cursor-pointer wameed-card">
              <div class="mx-5">
                <b-card-body class="mx-5 px-0">
                  <h5
                    class="
                      mb-0
                      text-medium-20 text-font-main
                      py-1
                      pt-1
                      mt-2
                      pb-5
                    "
                  >
                    {{ $t("project_requirements") }}
                  </h5>
                  <div
                    v-for="(item, i) in getWorkDetails.services"
                    :key="i"
                    class="d-flex flex-wrap py-1 my-2"
                  >
                    <b-badge
                      class="text-medium-14 mx-1 py-2 text-wrap"
                      variant="light-main "
                    >
                      {{ item.title }}
                    </b-badge>
                  </div>
                </b-card-body>
              </div>
            </b-card>
          </div>
        </b-col>
        <b-col lg="8" md="8" class="px-0 py-3">
          <b-card no-body class="cursor-pointer wameed-card">
            <b-card-header class="flex-nowrap align-items-start">
              <h5 class="text-medium-20 text-font-main">
                {{ $t("customer_details") }}
              </h5>
            </b-card-header>
            <b-card-body>
              <div class="d-flex flex-wrap py-3 mt-4 pt-1 border-bottom">
                <b-col>
                  <h5 class="mb-0 text-medium-16 text-font-sub mb-4 pb-1 mx-2">
                    {{ $t("full_name") }}
                  </h5>
                  <h5 class="mb-0 text-medium-16 text-font-main mb-4 pb-1 mx-2">
                    {{ getWorkDetails.name }}
                  </h5>
                </b-col>
                <b-col>
                  <h5 class="mb-0 text-medium-16 text-font-sub mb-4 pb-1 mx-2">
                    {{ $t("entity_type") }}
                  </h5>
                  <h5 class="mb-0 text-medium-16 text-font-main mb-4 pb-1 mx-2">
                    {{ $t(entities[getWorkDetails.entity_type - 1].text) }}
                  </h5>
                </b-col>
                <b-col>
                  <h5 class="mb-0 text-medium-16 text-font-sub mb-4 pb-1 mx-2">
                    {{ $t("job_position") }}
                  </h5>
                  <h5 class="mb-0 text-medium-16 text-font-main mb-4 pb-1 mx-2">
                    {{ getWorkDetails.job_position }}
                  </h5>
                </b-col>
              </div>
              <div class="d-flex flex-wrap py-3 mt-4 pt-1 border-bottom">
                <b-col>
                  <h5 class="mb-0 text-medium-16 text-font-sub mb-4 pb-1 mx-2">
                    {{ $t("email") }}
                  </h5>
                  <h5 class="mb-0 text-medium-16 text-font-main mb-4 pb-1 mx-2">
                    {{ getWorkDetails.email }}
                  </h5>
                </b-col>
                <b-col>
                  <h5 class="mb-0 text-medium-16 text-font-sub mb-4 pb-1 mx-2">
                    {{ $t("phone") }}
                  </h5>
                  <h5 dir="ltr" class="mb-0 text-medium-16 text-font-main mb-4 pb-1 mx-2">
                    {{ getWorkDetails.phone }}
                  </h5>
                </b-col>
              </div>
              <div
                v-if="getWorkDetails.description"
                class="d-flex flex-wrap py-3 mt-4 pt-1 border-bottom"
              >
                <b-col>
                  <h5 class="mb-0 text-medium-16 text-font-sub mb-4 pb-1 mx-2">
                    {{ $t("project_details") }}
                  </h5>
                  <span class="text-medium-16 text-font-main mb-4 pb-1 mx-2">
                    {{ getWorkDetails.description }}
                  </span>
                </b-col>
              </div>
              <div
                v-if="getWorkDetails.files && getWorkDetails.files.length > 0"
                class="d-flex flex-wrap py-3 mt-4 pt-1"
              >
                <b-col>
                  <h5 class="mb-0 text-medium-16 text-font-sub mb-4 pb-1 mx-2">
                    {{ $t("project_files") }}
                  </h5>
                  <!-- todo cv file download  -->
                  <div class="d-flex flex-wrap">
                    <div
                      v-for="(file, i) in getWorkDetails.files"
                      :key="i"
                      class="col-md-6 mb-4"
                    >
                      <b-media-body
                        class="py-4 px-5 border-main border-2 rounded-12"
                      >
                        <b-media no-body class="flex-wrap">
                          <b-media-aside class="mx-0">
                            <b-avatar variant="light-main" rounded size="52">
                              <file-icon />
                            </b-avatar>
                          </b-media-aside>
                          <b-media-body>
                            <h5
                              class="
                                mb-0
                                text-medium-12 text-font-main
                                mb-3
                                mx-2
                              "
                            >
                              {{ file.file_name }}
                            </h5>
                            <h5 class="mb-0 text-medium-16 text-main mx-2" dir="ltr">
                              {{ file.file_size }} KB
                            </h5>
                          </b-media-body>
                          <div class="flex">
                            <b-button
                              :href="file.full_path + file.file_name"
                              target="_blank"
                              class="px-2 mx-1"
                              variant="flat-white"
                            >
                              <eyeon-icon class="svg-main" />
                            </b-button>
                            <a
                              @click.prevent="loadFile(file.file_name)"
                              class="px-2 mx-1"
                              variant="flat-white"
                            >
                              <download-icon />
                            </a>
                          </div>
                        </b-media>
                      </b-media-body>
                    </div>
                  </div>
                </b-col>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      entities: [
        {
          icon: "individual-icon",
          text: "individual",
        },
        {
          icon: "company-icon",
          text: "company",
        },
        {
          icon: "organization-icon",
          text: "organization",
        },
        {
          icon: "government-icon",
          text: "government",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getWorkDetails: "getWorkDetails",
    }),
  },
  mounted() {
    this.loadWorkDetails(this.$router.currentRoute.params.id);
  },

  methods: {
    ...mapActions({
      loadWorkDetails: "loadWorkDetails",
      loadFile: "loadFile",
    }),
    formatedDate(date) {
      return this.moment().diff(this.moment(date), "days") > 7
        ? this.moment(date).format("YYYY-MM-DD")
        : this.moment(date).locale(this.$i18n.locale).fromNow(true);
    },
    // function to get the file size
  },
};
</script>
  

 
 